import React from "react";

// import ReCAPTCHA from "react-google-recaptcha";

import { Button, Form } from "antd";

import { useTranslation } from "react-i18next";
import "./i18n";

import InputMask from "react-input-mask";

import Input from "../../../../components/MyInput";
import { H3 } from "../../../../components/Typegraphy/Heading";
import { TextAreaWithFormLabel } from "../../../../components/Form/FormItemsWithLabel";

import { ContactFormInputModel, ContactFormProps } from "./types";
import { phoneNumberRegExp } from "../../../../utils/regExps";

// function ContactFrom({ onSendApplication, isDataLoading, isCaptchaError, onCaptchaChange }: ContactFormProps) {
function ContactFrom({ onSendApplication, isDataLoading }: ContactFormProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  // const captchaRef: any = useRef<HTMLLinkElement>(null);

  const onFinish = ({ fullname, phoneNumber, email, message }: ContactFormInputModel) => {
    // onSendApplication({ fullname, phoneNumber, email, message }, captchaRef.current.getValue());
    onSendApplication({ fullname, phoneNumber, email, message });
  };

  // useEffect(() => {
  //   if (isCaptchaError) {
  //     form.setFields([
  //       {
  //         name: "recaptcha",
  //         errors: [t("contact_form.captcha_error")],
  //       },
  //     ]);
  //   } else {
  //     form.setFields([
  //       {
  //         name: "recaptcha",
  //         errors: [],
  //       },
  //     ]);
  //   }
  // }, [form, t, isCaptchaError]);

  return (
    <>
      <H3 margin="0 0 16px 0">{t("contact_form.title")}</H3>
      <Form onFinish={onFinish} form={form}>
        <Form.Item name="fullname" rules={[{ required: true, message: t("contact_form.fullname_empty_error")! }]}>
          <Input placeholder={t("contact_form.fullname_placeholder")!} label={t("contact_form.fullname_label")!} mandatory />
        </Form.Item>
        <Form.Item
          name="phoneNumber"
          rules={[
            () => ({
              validator(_, value: string) {
                if (phoneNumberRegExp.test(value)) {
                  return Promise.resolve();
                }
                if (!value) {
                  return Promise.reject(t("contact_form.phone_number_empty_error"));
                }
                return Promise.reject(t("contact_form.phone_number_format_error"));
              },
            }),
          ]}
        >
          <InputMask mask="+7 (999) 999 - 9999">
            <Input placeholder={t("contact_form.phone_placeholder")!} label={t("contact_form.phone_label")!} mandatory />
          </InputMask>
        </Form.Item>
        <Form.Item
          name="email"
          rules={[
            { required: true, message: t("contact_form.email_empty_error")! },
            { type: "email", message: t("contact_form.email_format_error")! },
          ]}
        >
          <Input placeholder={t("contact_form.email_placeholder")!} label={t("contact_form.email_label")!} mandatory />
        </Form.Item>
        <Form.Item
          name="message"
          rules={[{ required: true, message: t("contact_form.message_empty_error")! }]}
          style={{ marginBottom: "40px" }}
        >
          <TextAreaWithFormLabel
            placeholder={t("contact_form.message_placeholder")!}
            label={t("contact_form.message_label")!}
            maxLength={300}
            showCount
            mandatory
            autoSize
          />
        </Form.Item>
        {/* <Form.Item name="recaptcha">
          <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITEKEY!} ref={captchaRef} onChange={onCaptchaChange} />
        </Form.Item> */}
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={isDataLoading}>
          {t("contact_form.send")}
        </Button>
      </Form>
    </>
  );
}

export default React.memo(ContactFrom);
