import IEntityDto from "../General/IEntityDto";

export enum ADDITIONAL_JOBS_TYPE_ENUM {
  FIRST_MILE = "FIRST_MILE",
  BEFORE_BORDER = "BEFORE_BORDER",
  TRANSPORTATION = "TRANSPORTATION",
  EXPORT_CLEARANCE = "EXPORT_CLEARANCE",
  AFTER_BORDER = "AFTER_BORDER",
  CUSTOMS_CLEARANCE = "CUSTOMS_CLEARANCE",
  LAST_MILE = "LAST_MILE",
  CARGO_INSURANCE = "CARGO_INSURANCE",
  CONTAINER_ISSUING = "CONTAINER_ISSUING",
}

export interface IAdditionalJobDto extends IEntityDto {
  name: string;
  type: ADDITIONAL_JOBS_TYPE_ENUM;
  isDefault: boolean;
  isEnable: boolean;
  isBase: boolean;
}
