import React, { useEffect } from "react";

import { Form, Button } from "antd";

import { useTranslation } from "react-i18next";

import { useSelector } from "react-redux";

import "./i18n";

import Input from "../../../../../../components/MyInput";

import { RestorePasswordFormProps, RestorePasswordFormInputModel } from "./types";

import { makeSelectRestorePasswordFormErrors } from "../../../../selectors";

function RestorePasswordForm({ loadingData, onRestorePassword }: RestorePasswordFormProps) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const formErrors = useSelector(makeSelectRestorePasswordFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = formErrors.map((x) => ({
        name: x.field,
        errors: [t(`restore_password_form.${x.field}_error_code_${x.errorCode}`)],
      }));

      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ email }: RestorePasswordFormInputModel) => {
    onRestorePassword({ email });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("restore_password_form.email_empty_error")! },
          { type: "email", message: t("restore_password_form.email_format_error")! },
        ]}
      >
        <Input placeholder="Email" label="Email" />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("restore_password_form.submit")}
        </Button>
      </Form.Item>
    </Form>
  );
}

export default React.memo(RestorePasswordForm);
