import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import "./i18n";

import { AuthActions } from "../../../../auth/actions";

import { makeSelectIsRestorePasswordLoading } from "../../selectors";

import { H3 } from "../../../../components/Typegraphy/Heading";
import { Text } from "../../../../components/Typegraphy/Text";
import RestorePasswordForm from "./components/RestorePasswordForm";

import { RestorePasswordInputModel } from "../../../../viewModels/Auth/RestorePasswordInputModel";

function RestorePasswordView() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isRestorePasswordLoading = useSelector(makeSelectIsRestorePasswordLoading());

  const handleRestorePassword = useCallback(
    (restorePasswordModel: RestorePasswordInputModel) => {
      dispatch(AuthActions.restorePassword(restorePasswordModel));
    },
    [dispatch],
  );

  return (
    <>
      <H3 margin="0 0 16px 0">{t("restore_password_view.title")}</H3>
      <Text margin="0 0 16px 0">{t("restore_password_view.text")}</Text>
      <RestorePasswordForm loadingData={isRestorePasswordLoading} onRestorePassword={handleRestorePassword} />
    </>
  );
}

export default React.memo(RestorePasswordView);
