import { MessageConfig } from "../../reducer/types";

const MessagesConfig = {
  updateProfileSuccess: {
    key: "update_profile_success",
    type: "success",
  },
  changesSaved: {
    key: "changes_saved",
    type: "success",
  },
  changesNotSaved: {
    key: "changes_not_saved",
    type: "error",
  },
  saveTemplateSuccess: {
    key: "save_template_success",
    type: "success",
  },
  saveTemplateError: {
    key: "save_template_error",
    type: "error",
  },
  deleteTemplateSuccess: {
    key: "delete_template_success",
    type: "success",
  },
  deleteTemplateError: {
    key: "delete_template_error",
    type: "error",
  },
  updateTemplateSuccess: {
    key: "update_template_success",
    type: "success",
  },
  updateTemplateError: {
    key: "update_template_error",
    type: "error",
  },
  sendFeedbackFormSuccess: {
    key: "send_feedback_form_success",
    type: "success",
  },
  calculateRoutesError: {
    key: "calculate_routes_error",
    type: "error",
  },
  orderEmailSended: {
    key: "order_email_sended",
    type: "success",
  },
  applicationRequest: {
    key: "application_request",
    type: "success",
  },
  applicationOnConfirmation: {
    key: "application_on_confirmation",
    type: "success",
  },
  applicationRejected: {
    key: "application_rejected",
    type: "success",
  },
  applicationCalculated: {
    key: "application_calculated",
    type: "success",
  },
  applicationInvoiced: {
    key: "application_invoiced",
    type: "success",
  },
  applicationDelivered: {
    key: "application_delivered",
    type: "success",
  },
  applicationClaimed: {
    key: "application_claimed",
    type: "success",
  },
  applicationCompleted: {
    key: "application_completed",
    type: "success",
  },
  applicationRevoked: {
    key: "application_revoked",
    type: "success",
  },
  applicationProcessed: {
    key: "application_processed",
    type: "success",
  },
  applicationBooked: {
    key: "application_booked",
    type: "success",
  },
  applicationInProcess: {
    key: "application_in_process",
    type: "success",
  },
  internalUserCreated: {
    key: "internal_user_created",
    type: "success",
  },
  internalUserUpdated: {
    key: "internal_user_updated",
    type: "success",
  },
  roleCreated: {
    key: "role_created",
    type: "success",
  },
  roleUpdated: {
    key: "role_updated",
    type: "success",
  },
  transporterCreated: {
    key: "transporter_created",
    type: "success",
  },
  transporterUpdated: {
    key: "transporter_updated",
    type: "success",
  },
  userMarkApplicationsAsRequiringAttentionSuccess: {
    key: "user_mark_applications_as_requiring_attention_success",
    type: "success",
  },
  userMarkApplicationsAsRequiringAttentionReject: {
    key: "user_mark_applications_as_requiring_attention_reject",
    type: "error",
  },
  coefficientUpdated: {
    key: "coefficient_updated",
    type: "success",
  },
  routeCreated: {
    key: "route_created",
    type: "success",
  },
  routeCreateError: {
    key: "route_create_error",
    type: "error",
  },
  routeExist: {
    key: "route_exist",
    type: "error",
  },
  routeUpdated: {
    key: "route_updated",
    type: "success",
  },
  routeUpdateError: {
    key: "route_update_error",
    type: "error",
  },
  exportRoutesSuccess: {
    key: "export_routes_success",
    type: "success",
  },
  exportRoutesError: {
    key: "export_routes_error",
    type: "error",
  },
  countryCreated: {
    key: "country_created",
    type: "success",
  },
  countryUpdated: {
    key: "country_updated",
    type: "success",
  },
  locationCreated: {
    key: "location_created",
    type: "success",
  },
  locationCreatedError: {
    key: "location_created_error",
    type: "error",
  },
  locationsLoadReject: {
    key: "locations_load_reject",
    type: "error",
  },
  routesLoadReject: {
    key: "routes_load_reject",
    type: "error",
  },
  currenciesLoadReject: {
    key: "currencies_load_reject",
    type: "error",
  },
  countriesLoadReject: {
    key: "countries_load_reject",
    type: "error",
  },
  countryUpdateReject: {
    key: "country_create_reject",
    type: "error",
  },
  countryCreateReject: {
    key: "countries_update_reject",
    type: "error",
  },
  coefficientsLoadReject: {
    key: "coefficients_load_reject",
    type: "error",
  },
  coefficientUpdateReject: {
    key: "coefficient_update_reject",
    type: "error",
  },
  applicationServicesLoadReject: {
    key: "application_services_load_reject",
    type: "error",
  },
  applicationServiceUpdated: {
    key: "application_service_updated",
    type: "success",
  },
  applicationServiceUpdateReject: {
    key: "application_service_update_reject",
    type: "error",
  },
  passwordUpdateSuccess: {
    key: "password_update_success",
    type: "success",
  },
  passwordUpdateReject: {
    key: "password_update_reject",
    type: "error",
  },

  usersLoadError: {
    key: "users_load_error",
    type: "error",
  },
  userCreateSuccess: {
    key: "user_create_success",
    type: "success",
  },
  userCreateError: {
    key: "user_create_error",
    type: "error",
  },
  userUpdateSuccess: {
    key: "user_update_success",
    type: "success",
  },
  userUpdateError: {
    key: "user_update_error",
    type: "error",
  },

  rolesLoadError: {
    key: "roles_load_error",
    type: "error",
  },
  roleCreateSuccess: {
    key: "role_create_success",
    type: "success",
  },
  roleCreateError: {
    key: "role_create_error",
    type: "error",
  },
  roleUpdateSuccess: {
    key: "role_create_success",
    type: "success",
  },
  roleUpdateError: {
    key: "role_create_error",
    type: "error",
  },
  assignUserToApplicationSuccess: {
    key: "assign_user_to_application_success",
    type: "success",
  },
  assignUserToApplicationError: {
    key: "assign_user_to_application_error",
    type: "error",
  },

  auditLogLoadError: {
    key: "audit_log_load_error",
    type: "error",
  },

  documentUploadSuccess: {
    key: "document_upload_success",
    type: "success",
  },
  documentUploadError: {
    key: "document_upload_error",
    type: "error",
  },

  importRoutesError: {
    key: "import_routes_error",
    type: "error",
  },
  importRoutesSuccess: {
    key: "import_routes_success",
    type: "success",
  },
  importRoutesSuccessWithErrors: {
    key: "import_routes_success_with_errors",
    type: "success",
  },

  generateNewPasswordSuccess: {
    key: "generate_new_password_success",
    type: "success",
  },
  generateNewPasswordError: {
    key: "generate_new_password_error",
    type: "error",
  },

  markForDeletionSuccess: {
    key: "mark_for_deletion_success",
    type: "success",
  },
  markForDeletionError: {
    key: "mark_for_deletion_error",
    type: "error",
  },

  unmarkForDeletionSuccess: {
    key: "unmark_for_deletion_success",
    type: "success",
  },
  unmarkForDeletionError: {
    key: "unmark_for_deletion_error",
    type: "error",
  },
  rateUpdateRoutesLoadReject: {
    key: "rate_update_load_load_reject",
    type: "error",
  },
  loadApplicationError: {
    key: "load_application_error",
    type: "error",
  },
  deleteDocumentError: {
    key: "delete_document_error",
    type: "error",
  },
  applicationServiceCreateSuccess: {
    key: "application_service_create_success",
    type: "success",
  },
  applicationServiceCreateError: {
    key: "application_service_create_error",
    type: "error",
  },
  applicationServiceDeleteSuccess: {
    key: "application_service_delete_success",
    type: "success",
  },
  applicationServiceDeleteError: {
    key: "application_service_deleteerror",
    type: "error",
  },
};

const typedMessagesConfig = MessagesConfig as { [key in keyof typeof MessagesConfig]: MessageConfig };

export { typedMessagesConfig as MessagesConfig };
