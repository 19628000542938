import React, { useEffect } from "react";

import { message } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import "./i18n";

import { makeSelectMessageConfigShow } from "../../selectors";

function MessageProvider() {
  const messageConfig = useSelector(makeSelectMessageConfigShow());

  const [messageApi, contextHolder] = message.useMessage();

  const { t } = useTranslation();

  useEffect(() => {
    if (messageConfig) {
      messageApi.open({
        type: messageConfig.type,
        content: messageConfig.orderInfo
          ? t(`message_provider.${messageConfig.key}`, messageConfig.orderInfo)
          : t(`message_provider.${messageConfig.key}`),
        className: `ant-message-notice-${messageConfig.type}`,
        icon: messageConfig.type === "error" ? <CloseCircleOutlined /> : <CheckCircleOutlined />,
        duration: 6,
      });
    }
  }, [messageConfig, messageApi, t]);

  return contextHolder;
}

export default React.memo(MessageProvider);
