import i18n from "../../../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  register_form: {
    login_placeholder: "10-12 цифр",
    login_label: "ИНН",
    login_empty_error: "Введите ИНН",
    login_size_error: "ИНН должен быть 10-12 цифр (знаков)",
    login_error_code_3: "Указанный ИНН уже зарегистрирован в системе, проверьте правильность ввода ИНН или попробуйте восстановить пароль",
    company_name_placeholder: "Наименование организации",
    company_name_label: "Наименование организации",
    company_name_empty_error: "Введите наименование организации",
    phone_number_placeholder: "+7 (___) ___ - ____",
    phone_number_label: "Номер телефона",
    phone_number_empty_error: "Введите номер телефона",
    phone_number_format_error: "Введите номер телефона в формате +7 (___) ___ - ____",
    email_empty_error: "Введите Email",
    email_format_error: "Неверный формат Email",
    email_error_code_3: "Указанный Email уже зарегистрирован в системе",
    agreements_text:
      "Нажимая кнопку “зарегистрироваться” вы даете согласие на обратботку ваших персональных данных на условиях, определенных",
    agreements_link: "Политикой обработки персональных данных.",
    submit: "Зарегистрироваться",
    is_already_registered: "Уже зарегистрированы?",
    login: "Войти",
  },
});
