import i18n from "../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  success_restore_password_view: {
    title: "Письмо отправлено",
    text: "Письмо для сброса пароля отправлено на вашу почту, если письма нет проверьте папку «Спам».",
    timer_text: "Повторная отправка возможна через",
    resend: "Отправить письмо еще раз",
  },
});
