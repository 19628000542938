import i18n from "../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  contact_form: {
    title: "Оставить заявку",
    fullname_label: "ФИО",
    fullname_placeholder: "Иванов Иван Иванович",
    fullname_empty_error: "Введите ФИО",
    phone_label: "Номер телефона",
    phone_placeholder: "+7 (___) ___ - ____",
    phone_number_empty_error: "Введите номер телефона",
    phone_number_format_error: "Введите номер телефона в формате +7 (___) ___ - ____",
    email_label: "Email",
    email_placeholder: "ivanmelnik@gmail.com",
    email_empty_error: "Введите Email",
    email_format_error: "Неверный формат Email",
    message_label: "Сообщение",
    message_placeholder: "Прошу мне перезвонить",
    message_empty_error: "Введите сообщение",
    captcha_error: "Подтвердите, что вы человек",
    send: "Отправить",
  },
});
