import i18n from "../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  success_register_view: {
    title: "Письмо отправлено",
    text: "Новый пароль отправлен на email. Если письма нет, проверьте папку Спам.",
    timer_text: "Повторная отправка возможна через",
    resend: "Отправить письмо еще раз",
  },
});
