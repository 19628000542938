// eslint-disable-next-line import/no-extraneous-dependencies
import moment from "moment";

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TransportationTypeModel } from "../../../viewModels/Settings/TrasportationType";

import AppSlice from "../../App/reducer";

import { SaveTemplateInputModel } from "../../../viewModels/Template/SaveTemplateInputModel";

import { ADDITIONAL_JOBS_TYPE_ENUM } from "../../../dto/Settings/IAdditionalJobDto";

import { CalculatedRoutesPayload, IHomeState, RouteOrderPayload, SearchParamsFromQS } from "./types";

import { RouteOrderFormInputModel } from "../components/ThirdSection/components/RouteOrder/components/RouteOrderForm/types";
import TemplateModel from "../../../viewModels/Template/TemplateModel";
import { CarrierBasicInfoModel } from "../../../viewModels/Carriers/CarrierBasicInfoModel";
import { RouteMethod } from "../../../viewModels/Routes/RouteModel";
import { ILocationDto } from "../../../dto/Location/ILocationDto";

const initialState: IHomeState = {
  loading: true,
  loadingData: true,

  selectedFromLocationValue: undefined!,
  selectedMiddleLocationValue: undefined!,
  selectedToLocationValue: undefined!,

  transportationTypeOptions: undefined!,
  defaultTrasportationTypeId: undefined!,
  selectedTransportationTypeIds: undefined!,

  currencyOptions: undefined!,
  defaultCurrencyId: undefined!,
  selectedCurrencyId: undefined!,

  selectedDate: undefined!,

  transportCharacteristicOptions: undefined!,
  defaultTransportCharacteristicId: undefined!,
  selectedTransportCharacteristicId: undefined!,
  defaultTransportCharacteristicValue: undefined!,
  transportCharacteristicValue: undefined!,
  containerTransportCharacteristicId: undefined!,

  weight: "0",
  count: "1",
  cost: "0",
  isDanger: false,

  routesOpenDetailsIds: [],
  pageSize: 10,

  routes: {
    hand: {
      routes: [],
      hasMoreRoutes: false,
      page: undefined!,
      pageSize: 10,
    },
    auto: {
      routes: [],
      hasMoreRoutes: false,
      page: undefined!,
      pageSize: 10,
    },
  },

  selectedAdditionalJobIds: undefined!,
  defaultAdditionalJobIds: undefined!,
  firstMileAdditionalJobId: undefined!,

  activeRoutesTabId: "CHEAP",

  searchParamsSnapshot: undefined!,

  selectedRouteIdForOrder: undefined!,
  conditionDeliveries: undefined!,
  isOrderTransportationLoading: false,

  isMakeRequestLoading: false,

  orderFormForTemplate: undefined!,

  isSaveTemplateModalLoading: false,
  isSaveTemplateModalVisible: false,
  isSaveTemplateModalLoadingTemplates: false,

  templates: undefined!,
  carriers: [],

  // VIEW
  isShowTitle: false,
  isMainScreen: true,
};

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setLoadingData(state, action: PayloadAction<boolean>) {
      state.loadingData = action.payload;
    },
    changeFromLocationValue(state, action: PayloadAction<ILocationDto>) {
      state.selectedFromLocationValue = action.payload;
    },
    changeMiddleLocationValue(state, action: PayloadAction<ILocationDto>) {
      state.selectedMiddleLocationValue = action.payload;
    },
    changeToLocationValue(state, action: PayloadAction<ILocationDto>) {
      state.selectedToLocationValue = action.payload;
    },
    clearMiddleLocation(state) {
      state.selectedMiddleLocationValue = undefined!;
    },
    swapFromToLocations(state) {
      const bufferFromLocationValue = state.selectedFromLocationValue;
      state.selectedFromLocationValue = state.selectedToLocationValue;
      state.selectedToLocationValue = bufferFromLocationValue;
    },
    changeTransportationTypeIds(state, action: PayloadAction<TransportationTypeModel>) {
      if (state.selectedTransportationTypeIds.includes(action.payload.id)) {
        state.selectedTransportationTypeIds = state.selectedTransportationTypeIds.filter((item) => item !== action.payload.id);
      } else {
        state.selectedTransportationTypeIds.push(action.payload.id);
      }
    },
    changeCurrencyId(state, action: PayloadAction<number>) {
      state.selectedCurrencyId = action.payload;
    },
    changeDate(state, action: PayloadAction<moment.Moment>) {
      state.selectedDate = action.payload;
    },
    changeTransportCharacteristicId(state, action: PayloadAction<number>) {
      state.selectedTransportCharacteristicId = action.payload;

      const jobsIds = state.defaultAdditionalJobIds.map((x) => +x);

      if (action.payload === 2) {
        state.selectedAdditionalJobIds = jobsIds.filter((x) => x !== 9);
        state.selectedTransportationTypeIds = [2];
      } else {
        state.selectedAdditionalJobIds = jobsIds;
        state.selectedTransportationTypeIds = [1, 2, 3];
      }

      const selectedTransportCharacteristic = state.transportCharacteristicOptions.find((x) => x.value === action.payload);
      if (selectedTransportCharacteristic?.type === "LIST") {
        state.transportCharacteristicValue = selectedTransportCharacteristic.defaultValue;
      } else {
        state.transportCharacteristicValue = "";
      }
    },
    changeTransportCharacteristicValue(state, action: PayloadAction<string>) {
      state.transportCharacteristicValue = action.payload;
    },
    changeWeight(state, action: PayloadAction<string>) {
      state.weight = action.payload;
    },
    changeCount(state, action: PayloadAction<string>) {
      state.count = action.payload;
    },
    changeCost(state, action: PayloadAction<string>) {
      state.cost = action.payload;
    },
    changeIsDanger(state, action: PayloadAction<boolean>) {
      state.isDanger = action.payload;
    },
    resetSearchForm(state) {
      state.selectedFromLocationValue = undefined!;
      state.selectedToLocationValue = undefined!;
      state.selectedMiddleLocationValue = undefined!;
      state.selectedTransportationTypeIds = [state.defaultTrasportationTypeId];
      state.selectedCurrencyId = state.defaultCurrencyId;
      state.selectedDate = undefined!;
      state.selectedTransportCharacteristicId = state.defaultTransportCharacteristicId;
      state.transportCharacteristicValue = state.defaultTransportCharacteristicValue;
      state.weight = "0";
      state.count = "1";
      state.cost = "0";
      state.isDanger = false;
    },
    changeAdditionalJobIds(state, action: PayloadAction<number[]>) {
      state.selectedAdditionalJobIds = action.payload;
    },
    resetRoutes(state, action: PayloadAction<RouteMethod>) {
      const routesType = action.payload;
      state.routes[routesType].routes = [];
    },
    calculateRoutes(state) {
      state.loadingData = true;
      state.isMainScreen = false;
      state.activeRoutesTabId = "CHEAP";
      if (!state.cost) {
        state.cost = "0";
      }

      if (state.selectedTransportCharacteristicId === state.containerTransportCharacteristicId) {
        state.selectedAdditionalJobIds = state.defaultAdditionalJobIds.concat(state.firstMileAdditionalJobId);
      }
    },
    setRoutesOpenDetailsIds(state, action: PayloadAction<number>) {
      const routeId = action.payload;
      if (state.routesOpenDetailsIds.includes(routeId)) {
        state.routesOpenDetailsIds = state.routesOpenDetailsIds.filter((id) => id !== routeId);
      } else {
        state.routesOpenDetailsIds.push(routeId);
      }
    },
    resetRoutesOpenDetailsIds(state) {
      state.routesOpenDetailsIds = [];
    },
    setIsShowTitle(state, action: PayloadAction<boolean>) {
      state.isShowTitle = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    loadCarriers(_, action: PayloadAction<number[]>) {},
    loadCarriersSuccess(state, action: PayloadAction<CarrierBasicInfoModel[]>) {
      const carriers = [...state.carriers, ...action.payload];
      const uniqIds = new Set(carriers.map((x) => x.id));
      const uniqCarriers: CarrierBasicInfoModel[] = [];
      uniqIds.forEach((id) => {
        const carrier = carriers.find((x) => x.id === id);
        if (carrier) uniqCarriers.push(carrier);
      });

      state.carriers = uniqCarriers;
    },
    calculateRoutesError(state) {
      state.loadingData = false;
    },
    calculatedRoutesSuccess(state, action: PayloadAction<CalculatedRoutesPayload>) {
      const { routes, snapshot, hasMore, page, routesType } = action.payload;

      state.routes[routesType].hasMoreRoutes = hasMore;
      state.routes[routesType].page = page;

      if (page === 1) {
        state.routes[routesType].routes = routes;
      } else {
        state.routes[routesType].routes = state.routes[routesType].routes.concat(routes);
      }

      if (snapshot) {
        state.searchParamsSnapshot = snapshot;
      }
    },
    changeActiveRouteTabId(state, action: PayloadAction<string>) {
      state.loadingData = true;
      state.activeRoutesTabId = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    showMoreRoutes(state, action: PayloadAction<RouteMethod>) {
      state.loadingData = true;
    },
    backButtonClick(state) {
      state.selectedRouteIdForOrder = undefined!;
    },
    selectRouteForOrder(state, action: PayloadAction<number>) {
      state.selectedRouteIdForOrder = action.payload;
    },
    // eslint-disable-next-line no-unused-vars
    orderTransportation(state, action: PayloadAction<RouteOrderPayload>) {
      state.isOrderTransportationLoading = true;
    },
    orderTransportationSuccess(state) {
      state.isOrderTransportationLoading = false;
    },
    orderTransportationError(state) {
      state.isOrderTransportationLoading = false;
    },
    // eslint-disable-next-line no-unused-vars
    makeRequest(state, action: PayloadAction<string>) {
      state.isMakeRequestLoading = true;
    },
    makeRequestSuccess(state) {
      state.isMakeRequestLoading = false;
    },
    makeRequestError(state) {
      state.isMakeRequestLoading = false;
    },
    openSaveTemplateModal(state, action: PayloadAction<RouteOrderFormInputModel>) {
      state.isSaveTemplateModalVisible = true;
      state.orderFormForTemplate = action.payload;
    },
    closeSaveTemplateModal(state) {
      if (!state.isSaveTemplateModalLoading) {
        state.isSaveTemplateModalVisible = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    saveTemplate(state, action: PayloadAction<SaveTemplateInputModel>) {
      state.isSaveTemplateModalLoading = true;
    },
    saveTemplateSuccess(state) {
      state.isSaveTemplateModalVisible = false;
      state.isSaveTemplateModalLoading = false;
    },
    saveTemplateError(state) {
      state.isSaveTemplateModalLoading = false;
    },

    // eslint-disable-next-line no-unused-vars
    deleteTemplate(state, action: PayloadAction<number>) {
      state.isSaveTemplateModalLoading = true;
    },
    deleteTemplateSuccess(state, action: PayloadAction<number>) {
      state.isSaveTemplateModalLoading = false;
      state.templates = state.templates.filter((template) => template.id !== action.payload);
    },
    deleteTemplateError(state) {
      state.isSaveTemplateModalLoading = false;
    },

    loadTemplates(state) {
      state.isSaveTemplateModalLoadingTemplates = true;
    },
    loadTemplatesSuccess(state, action: PayloadAction<Array<TemplateModel>>) {
      state.isSaveTemplateModalLoadingTemplates = false;
      state.templates = action.payload;
    },
    loadTemplatesError(state) {
      state.isSaveTemplateModalLoadingTemplates = false;
      state.templates = [];
    },
    resetState(state) {
      const isFCL = state.selectedTransportCharacteristicId === 1;
      state.isMainScreen = true;
      state.selectedFromLocationValue = undefined!;
      state.selectedToLocationValue = undefined!;
      state.selectedMiddleLocationValue = undefined!;
      // state.selectedTransportationTypeIds = [state.defaultTrasportationTypeId];
      state.selectedTransportationTypeIds = isFCL ? [1, 2, 3] : [2];
      state.selectedCurrencyId = state.defaultCurrencyId;
      state.selectedDate = undefined!;
      state.transportCharacteristicValue = state.defaultTransportCharacteristicValue;
      state.selectedAdditionalJobIds = state.defaultAdditionalJobIds;
      state.weight = "0";
      state.count = "1";
      state.cost = "0";
      state.isDanger = false;
      state.selectedRouteIdForOrder = undefined!;
      state.routes.hand.routes = [];
      state.routes.hand.hasMoreRoutes = false;
      state.routes.auto.routes = [];
      state.routes.auto.hasMoreRoutes = false;
      state.loading = false;
      state.loadingData = false;
      state.isOrderTransportationLoading = false;
    },
    // eslint-disable-next-line no-unused-vars
    calculateRoutesByQs(state, action: PayloadAction<SearchParamsFromQS>) {
      state.loadingData = true;
      const {
        from,
        to,
        transportationTypeIds,
        currencyId,
        date,
        transportCharacteristicId,
        transportCharacteristicValue,
        weight,
        count,
        cost,
        isDanger,
        additionalJobIds,
        activeRoutesTabId,
      } = action.payload;

      if (action.payload.waypoint) {
        state.selectedMiddleLocationValue = { ...action.payload.waypoint, id: +action.payload.waypoint.id };
      }
      state.selectedFromLocationValue = { ...from, id: +from.id };
      state.selectedToLocationValue = { ...to, id: +to.id };
      state.selectedTransportationTypeIds = transportationTypeIds.map((x) => +x);
      state.selectedCurrencyId = +currencyId;
      state.selectedDate = moment(date);
      state.selectedTransportCharacteristicId = +transportCharacteristicId;
      state.transportCharacteristicValue = transportCharacteristicValue;
      state.weight = weight;
      state.count = count;
      state.cost = cost;
      state.isDanger = isDanger === "true";
      state.isMainScreen = false;
      state.selectedAdditionalJobIds = additionalJobIds ? additionalJobIds.map((x) => +x) : [];
      state.activeRoutesTabId = activeRoutesTabId;
      state.selectedRouteIdForOrder = undefined!;
    },
  },
  extraReducers(builder) {
    builder.addCase(AppSlice.actions.settingsLoaded, (state, action) => {
      const { additionalJobs, transportationTypes, currencies, transportCharacteristics, conditionDeliveries } = action.payload;

      state.transportationTypeOptions = transportationTypes;
      state.defaultTrasportationTypeId = transportationTypes.find((x) => x.isDefault)?.id!;
      state.selectedTransportationTypeIds = [state.defaultTrasportationTypeId];

      state.currencyOptions = currencies.map((x) => ({
        label: x.name,
        value: x.id,
      }));
      state.defaultCurrencyId = currencies.find((x) => x.isDefault)?.id!;
      state.selectedCurrencyId = state.defaultCurrencyId;

      state.transportCharacteristicOptions = transportCharacteristics
        .filter((x) => x.loadType === "FCL" || x.loadType === "FTL") // Фильтруем для старого калькулятора, т.к. авиа и т.д. на нем нет
        .map((x) => ({
          value: x.id,
          label: x.name,
          type: x.type,
          defaultValue: x.type === "LIST" ? x.possibleValues?.find((y) => y.isDefault)?.containerType! : undefined!,
        }));

      state.containerTransportCharacteristicId = transportCharacteristics.find((x) => x.loadType === "FCL")?.id!;

      const defaultTransportCharacteristicValue = transportCharacteristics.find((x) => x.isDefault);
      state.defaultTransportCharacteristicId = defaultTransportCharacteristicValue?.id!;
      state.selectedTransportCharacteristicId = state.defaultTransportCharacteristicId;
      state.defaultTransportCharacteristicValue = defaultTransportCharacteristicValue?.possibleValues?.find(
        (x) => x.isDefault,
      )?.containerType!;
      state.transportCharacteristicValue = state.defaultTransportCharacteristicValue;

      const defaultAdditionalJobIds = additionalJobs.filter((x) => x.isDefault).map((x) => x.id);
      state.selectedAdditionalJobIds = defaultAdditionalJobIds;
      state.defaultAdditionalJobIds = defaultAdditionalJobIds;

      state.firstMileAdditionalJobId = additionalJobs.find((x) => x.type === ADDITIONAL_JOBS_TYPE_ENUM.FIRST_MILE)?.id!;

      state.conditionDeliveries = conditionDeliveries.map((x) => ({
        label: x.code,
        value: x.id,
      }));
    });
  },
});

export default homeSlice;
