import React, { useCallback, useEffect } from "react";

// eslint-disable-next-line
import { useNavigate } from "react-router";

import { Form, Button, Row } from "antd";

import InputMask from "react-input-mask";

import { useTranslation } from "react-i18next";

import { useSelector, useDispatch } from "react-redux";

import "./i18n";

import AppSlice from "../../../../../App/reducer";

import { makeSelectRegistrationFormErrors } from "../../../../selectors";

import Input from "../../../../../../components/MyInput";
import { Text } from "../../../../../../components/Typegraphy/Text";

import { Agreements } from "./styled";

import { RegisterFormProps, RegisterFormInputModel } from "./types";
import { phoneNumberRegExp } from "../../../../../../utils/regExps";

function RegisterForm({ loadingData, onRegister, onOpenLoginView }: RegisterFormProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formErrors = useSelector(makeSelectRegistrationFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = formErrors.map((x) => ({
        name: x.field,
        errors: [t(`register_form.${x.field}_error_code_${x.errorCode}`)],
      }));

      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ login, companyName, phoneNumber, email }: RegisterFormInputModel) => {
    onRegister({ login, companyName, phoneNumber, email });
  };

  const handleBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length >= 10 && e.target.value.length <= 12) {
        (async () => {
          try {
            const response = await fetch(`https://egrul.itsoft.ru/short_data/?${e.target.value}}`);
            const result: any = await response.json();
            form.setFields([
              {
                name: "companyName",
                value: result.full_name,
                errors: undefined,
              },
            ]);
          } catch (err) {
            console.log("No registered companies found");
          }
        })();
      }
    },
    [form],
  );

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="login"
        rules={[
          () => ({
            validator(_, value: string) {
              if (value && value.length >= 10 && value.length <= 12) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("register_form.login_empty_error"));
              }
              return Promise.reject(t("register_form.login_size_error"));
            },
          }),
        ]}
      >
        <Input
          placeholder={t("register_form.login_placeholder")!}
          label={t("register_form.login_label")!}
          mandatory
          type="only-numbers"
          onBlur={handleBlur}
        />
      </Form.Item>

      <Form.Item name="companyName" rules={[{ required: true, message: t("register_form.company_name_empty_error")! }]}>
        <Input placeholder={t("register_form.company_name_placeholder")!} label={t("register_form.company_name_label")!} mandatory />
      </Form.Item>

      <Form.Item
        name="phoneNumber"
        rules={[
          () => ({
            validator(_, value: string) {
              if (phoneNumberRegExp.test(value)) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("register_form.phone_number_empty_error"));
              }
              return Promise.reject(t("register_form.phone_number_format_error"));
            },
          }),
        ]}
      >
        <InputMask mask="+7 (999) 999 - 9999">
          <Input placeholder={t("register_form.phone_number_placeholder")!} label={t("register_form.phone_number_label")!} mandatory />
        </InputMask>
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("register_form.email_empty_error")! },
          { type: "email", message: t("register_form.email_format_error")! },
        ]}
      >
        <Input placeholder="Email" label="Email" mandatory />
      </Form.Item>

      <Agreements
        onClick={() => {
          dispatch(AppSlice.actions.closeAuthModal());
          navigate("/privacy/some1");
        }}
      >
        {t("register_form.agreements_text")} <span>{t("register_form.agreements_link")}</span>
      </Agreements>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("register_form.submit")}
        </Button>
      </Form.Item>

      <Row justify="center">
        <Text size={3} margin="0 4px 0">
          {t("register_form.is_already_registered")}
        </Text>
        <Button onClick={onOpenLoginView} type="text">
          {t("register_form.login")}
        </Button>
      </Row>
    </Form>
  );
}

export default React.memo(RegisterForm);
