import i18n from "../../../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  restore_password_form: {
    email_empty_error: "Введите Email",
    email_format_error: "Неверный формат Email",
    email_error_code_2: "Указанный Email не зарегистрирован в системе, проверьте правильность адреса или зарегистрируйтесь",
    submit: "Отправить",
  },
});
