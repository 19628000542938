import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AutoComplete, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import "./i18n";

import { LocationOptionModel, LocationPickerProps } from "./types";
import { ILocationDto } from "../../dto/Location/ILocationDto";

import { generateLocationLabel, getIconByLocationType } from "./utils";
import { AutoCompleteContainer, IconContainer } from "./styled";
import { fetchLocations } from "../V2Components/LocationPicker/utils";

const SpinIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function LocationPicker({ value, placeholder, onSelect, width, onInputChange }: LocationPickerProps) {
  const { t } = useTranslation();

  const isChangedBySelect = useRef<boolean>(false);
  const isSelected = useRef<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [locations, setLocations] = useState<Array<ILocationDto>>([]);
  const [options, setOptions] = useState<LocationOptionModel[]>([]);

  useEffect(() => {
    if (value) {
      setInputValue(value.name);
      isSelected.current = true;
    } else {
      setInputValue("");
    }
  }, [value]);

  useEffect(() => {
    const abortController = new AbortController();
    if (inputValue.length >= 3 && !isChangedBySelect.current) {
      setLocations([]);
      setIsLoading(true);

      (async () => {
        try {
          const locationsResult = await fetchLocations(abortController, inputValue);
          setLocations(locationsResult);
          setIsLoading(false);
        } catch (err) {
          console.log(`No locations found matching the pattern ${inputValue}`);
        }
      })();
    }
    isChangedBySelect.current = false;

    return () => {
      abortController.abort();
    };
  }, [inputValue]);

  useEffect(() => {
    setOptions(
      inputValue.length < 3
        ? []
        : locations.map(({ id, name, type }: ILocationDto) => ({
            id,
            value: `${name}_${type}`,
            label: generateLocationLabel(type, name),
          })),
    );
  }, [locations, inputValue]);

  const handleSelect = useCallback(
    (_value: string, option: LocationOptionModel) => {
      isSelected.current = true;
      isChangedBySelect.current = true;
      const findedLocation = locations.find((location) => location.id === option.id)!;
      onSelect(findedLocation);
    },
    [locations, onSelect],
  );

  const handleChange = useCallback(
    (v: string) => {
      setInputValue(v);
      if (isSelected.current) {
        isSelected.current = false;
      }
      if (onInputChange) {
        onInputChange(v);
      }
    },
    [onInputChange],
  );

  const handleBlur = useCallback(() => {
    const isValueNotChoosed: boolean = locations.every((location) => location.name !== inputValue);
    if (isValueNotChoosed) {
      setInputValue("");
      if (onInputChange) {
        onInputChange("");
      }
    }
  }, [locations, inputValue, onInputChange]);

  const notFoundContent = useMemo(() => {
    if (isLoading) return <Spin indicator={SpinIcon} />;
    if (inputValue.length < 3) return "";
    return t("location_picker.not_found");
  }, [inputValue, t, isLoading]);

  const prefixIcon = useMemo(() => {
    const isValueNotChoosed: boolean = locations.every((location) => location.name !== inputValue);
    return !isValueNotChoosed && value ? getIconByLocationType(value?.type) : null;
  }, [inputValue, value, locations]);

  return (
    <AutoCompleteContainer hasPrefixIcon={!!prefixIcon}>
      <IconContainer>{prefixIcon}</IconContainer>
      <AutoComplete
        placeholder={placeholder}
        value={inputValue}
        onSearch={handleChange}
        style={{ width }}
        onSelect={handleSelect}
        onBlur={handleBlur}
        notFoundContent={notFoundContent}
        popupClassName={isLoading ? "ant-select-dropdown-loading" : ""}
        open={!!options && !isSelected.current}
      >
        {options.map((option) => (
          <AutoComplete.Option key={option.id} id={option.id} value={option.value}>
            {option.label}
          </AutoComplete.Option>
        ))}
      </AutoComplete>
    </AutoCompleteContainer>
  );
}

export default React.memo(LocationPicker);
