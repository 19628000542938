import i18n from "../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  message_provider: {
    changes_saved: "Изменения сохранены",
    changes_not_saved: "Ошибка при сохранения изменений",
    update_profile_success: "Информация сохранена",
    save_template_success: "Новый шаблон сохранен",
    save_template_error: "Ошибка сохранения шаблона",
    delete_template_success: "Шаблон удалён",
    delete_template_error: "Ошибка удаления шаблона",
    update_template_success: "Шаблон обновлен",
    update_template_error: "Ошибка обновления шаблона",
    send_feedback_form_success: "Ваше сообщение отправлено",
    calculate_routes_error: "Расчет перевозки временно недоступен. Пожалуйста, попробуйте позже или обратитесь в службу поддержки.",
    order_email_sended: "Заказ отправлен на электронную почту",
    application_rejected: "Заявка № {{orderId}} от {{date}} переведена в статус “Отклонена”.",
    application_calculated: "Заявка № {{orderId}} от {{date}} переведена в статус “Выполнить расчёт”.",
    application_request: "Заявка № {{orderId}} от {{date}} переведена в статус “Запрос”.",
    application_on_confirmation: "Заявка № {{orderId}} от {{date}} переведена в статус “На подтверждении”.",
    application_invoiced: "Заявка № {{orderId}} от {{date}} переведена в статус “Выставлен счёт”.",
    application_revoked: "Заявка № {{orderId}} от {{date}} переведена в статус “Отозвано”.",
    application_delivered: "Заявка № {{orderId}} от {{date}} переведена в статус “Груз доставлен”.",
    application_claimed: "Заявка № {{orderId}} от {{date}} переведена в статус “Претензия”.",
    application_completed: "Заявка № {{orderId}} от {{date}} переведена в статус “Завершена”.",
    application_in_process: "Заявка № {{orderId}} от {{date}} переведена в статус “Выполняется”.",
    application_booked: "Заявка № {{orderId}} от {{date}} переведена в статус “Букинг”.",
    internal_user_created: "Новый пользователь сохранен",
    internal_user_updated: "Пользователь отредактирован",
    role_created: "Новая роль сохранена",
    role_updated: "Роль отредактирована",
    transporter_created: "Новый перевозчик сохранен",
    transporter_updated: "Перевозчик отредактирован",
    user_mark_applications_as_requiring_attention_success: "Отмечено как Требующие внимания",
    user_mark_applications_as_requiring_attention_reject: "Произошла ошибка",
    coefficient_updated: "Коэффициент отредактирован",
    route_created: "Маршрут сохранён",
    route_create_error: "Не удалось сохранить маршрут",
    route_exist: "Не удалось выполнить действие. Такой маршрут уже существует.",
    route_updated: "Маршрут отредактирован",
    route_update_error: "Не удалось отредактировать маршрут",
    import_routes_error: "Произошла ошибка. Файл не загружен",
    import_routes_success: "Файл успушно загружен",
    import_routes_success_with_errors: "Файл загружен не полностью. Пожалуйста проверьте информацию об ошибках",
    export_routes_success: "Экспорт завершён",
    export_routes_error: "Не удалось экспортировать файл",
    country_created: "Новая страна создана",
    country_updated: "Страна отредактирована",
    location_created: "Локация сохранена",
    location_created_error: "Не удалось сохранить локацию",
    locations_load_reject: "Не удалось загрузить локации",
    routes_load_reject: "Не удалось загрузить маршруты",
    currencies_load_reject: "Не удалось загрузить курсы ЦБ РФ",
    countries_load_reject: "Не удалось загрузить страны",
    country_create_reject: "Не удалось создать страну",
    country_update_reject: "Не удалось обновить страну",
    coefficients_load_reject: "Не удалось загрузить коэффициенты",
    coefficient_update_reject: "Не удалось обновить коэффициент",
    applicationServicesLoadReject: "Не удалось загрузить доп. услуги",
    applicationServiceUpdateReject: "Не удалось обновить доп. услугу",
    password_update_success: "Пароль успешно изменен",
    password_update_reject: "Не удалось изменить пароль",

    users_load_error: "Не удалось загрузить пользователей",
    user_create_success: "Новый пользователь сохранен",
    user_create_error: "Не удалось создать нового пользователя",

    user_update_success: "Пользователь успешно обновлен",
    user_update_error: "Не удалось обновить пользователя",

    roles_load_error: "Не удалось загрузить роли",
    role_create_success: "Новая роль сохранена",
    role_create_error: "Не удалось создать новую роль",

    role_update_success: "Роль успешно обновлена",
    role_update_error: "Не удалось обновить роль",

    assign_user_to_application_success: "Пользователь успешно назначен",
    assign_user_to_application_error: "Не удалось назначить пользователя",

    audit_log_load_error: "Не удалось загрузить журнал аудита",

    document_upload_success: "Документ успешно загружен",
    document_upload_error: "Не удалось загрузить документ",

    generate_new_password_success: "Новый пароль отправлен на email",
    generate_new_password_error: "Не удалось сгенерировать новый пароль",

    mark_for_deletion_success: "Маршруты помечены на удаление",
    mark_for_deletion_error: "Ошибка при операции",

    unmark_for_deletion_success: "Маршруты восстановлены",
    unmark_for_deletion_error: "Ошибка при операции",

    rate_update_load_load_reject: "Ошибка при загрузке",

    load_application_error: "Котировки с таким идентификатором не существет или у вас нет доступа к этой котировке",

    delete_document_error: "Ошибка при удалении файла: файл прикреплен к котировке",

    application_services_load_reject: "Не удалось загрузить доп. услуги",
    application_service_updated: "Доп. услуга отредактирована",
    application_service_update_reject: "Не удалось обновить доп. услугу",

    application_service_create_success: "Новая доп. услуга сохранена",
    application_service_create_error: "Не удалось создать новую доп. услугу",

    application_service_delete_success: "Доп. услуга удалена",
    application_service_delete_error: "Не удалось удалить доп. услугу",
  },
});
