import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ApplicationModel, ApplicationFilter } from "../../../viewModels/Applications/ApplicationModel";
import RouteModel from "../../../viewModels/Routes/RouteModel";
import exhaustiveCheck from "../../../utils/exhaustiveCheck";
import ApplicationServiceModel from "../../../viewModels/Applications/ApplicationServiceModel";
import { UserBasicInfoModel } from "../../../viewModels/User/UserBasicInfoModel";

const initialState: {
  loading: boolean;
  loadingData: boolean;

  application: ApplicationModel;

  routes?: Array<RouteModel>;
  digitalLogistTotalRoutes?: number;
  fescoTotalRoutes?: number;
  services?: Array<ApplicationServiceModel>;
  cheapRoutesIds?: Array<string>;
  fastRoutesIds?: Array<string>;
  fescoRouteIds?: Array<string>;

  loadingDigitalLogistVariants: boolean;
  loadingMoreCheapVariants: boolean;
  loadingMoreFastVariants: boolean;
  loadingFescoVariants: boolean;

  logisticians?: Array<UserBasicInfoModel>;
} = {
  loading: true,
  loadingData: true,

  application: undefined!,

  loadingDigitalLogistVariants: false,
  loadingMoreCheapVariants: false,
  loadingMoreFastVariants: false,
  loadingFescoVariants: false,
};

const salesApplicationSlice = createSlice({
  name: "salesApplication",
  initialState,
  reducers: {
    loadApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<number>,
    ) {
      state.loadingData = true;
    },
    loadApplicationSuccess(
      state,
      action: PayloadAction<{
        application: ApplicationModel;
        services?: Array<ApplicationServiceModel>;
        selectedRoute?: RouteModel;
      }>,
    ) {
      const { application, services, selectedRoute } = action.payload;

      state.loading = false;
      state.loadingData = false;
      state.application = application;
      state.services = services;
      if (selectedRoute) {
        state.routes = [selectedRoute];
      }
    },
    loadVariants(
      state,
      action: PayloadAction<{
        filter: ApplicationFilter;
        routeFilter: {
          page: number;
          pageSize: number;
          routeSortingType: "CHEAP" | "FAST";
        };
        dropVariants?: boolean;
      }>,
    ) {
      const { filter, dropVariants, routeFilter } = action.payload;

      if (dropVariants) {
        state.loadingDigitalLogistVariants = true;
        if (filter.transportCharacteristic.loadType === "FCL") {
          state.loadingFescoVariants = true;
        }
        state.routes = [];
        state.cheapRoutesIds = undefined;
        state.fastRoutesIds = undefined;
        state.fescoRouteIds = undefined;
        state.digitalLogistTotalRoutes = undefined;
        state.fescoTotalRoutes = undefined;
      }

      if (routeFilter.routeSortingType === "CHEAP") {
        state.loadingMoreCheapVariants = true;
      } else {
        state.loadingMoreFastVariants = true;
      }
    },
    loadVariantsSuccess(
      state,
      action: PayloadAction<{
        routes?: Array<RouteModel>;
        routeSortingType: "CHEAP" | "FAST";
        total: number;
        selectedRoute?: RouteModel;
      }>,
    ) {
      const { routes, routeSortingType, total, selectedRoute } = action.payload;

      if (!state.digitalLogistTotalRoutes) {
        state.digitalLogistTotalRoutes = total;
      } else {
        state.digitalLogistTotalRoutes += total;
      }

      if (routes) {
        if (!routes) {
          state.routes = [];
        }
        routes.forEach((route) => {
          if (!state.routes?.find((x) => x.routeId === route.routeId)) {
            state.routes?.push(route);
          }
        });

        if (selectedRoute && !state.routes?.find((x) => x.routeId === selectedRoute.routeId)) {
          state.routes?.push(selectedRoute);
        }

        switch (routeSortingType) {
          case "CHEAP":
            if (!state.cheapRoutesIds) {
              state.cheapRoutesIds = [];
            }
            state.cheapRoutesIds = state.cheapRoutesIds.concat(routes.map((x) => x.routeId));
            state.loadingMoreCheapVariants = false;
            break;
          case "FAST":
            if (!state.fastRoutesIds) {
              state.fastRoutesIds = [];
            }
            state.fastRoutesIds = state.fastRoutesIds.concat(routes.map((x) => x.routeId));
            state.loadingMoreFastVariants = false;
            break;
          default:
            exhaustiveCheck(routeSortingType);
        }
      }

      state.loadingDigitalLogistVariants = false;
    },
    loadFescoVariantsSuccess(
      state,
      action: PayloadAction<{
        routes?: Array<RouteModel>;
        total: number;
      }>,
    ) {
      const { routes, total } = action.payload;

      state.fescoTotalRoutes = total;

      if (routes) {
        if (!state.routes) {
          state.routes = [];
        }

        routes.forEach((route) => {
          if (!state.routes?.find((x) => x.routeId === route.routeId)) {
            state.routes?.push(route);
          }
        });

        state.fescoRouteIds = routes.map((x) => x.routeId);

        // По идее такого не должно быть, чтобы выбранный маршрут не пришел в основной пачке
        // if (selectedRoute && !state.routes?.find((x) => x.routeId === selectedRoute.routeId)) {
        //   state.routes?.push(selectedRoute);
        // }
      }

      state.loadingFescoVariants = false;
    },

    loadServices(
      // eslint-disable-next-line no-unused-vars
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        filter: ApplicationFilter;
      }>,
    ) {},

    loadServicesSuccess(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Array<ApplicationServiceModel>>,
    ) {
      state.services = action.payload;
    },

    resetState: () => initialState,

    updateApplication(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<Omit<ApplicationModel, "type" | "creationDate" | "indicativeCreationDate" | "creator">>,
    ) {
      state.loadingData = true;
    },
    updateApplicationSuccess(state, action: PayloadAction<ApplicationModel>) {
      state.loadingData = false;
      state.application = action.payload;
    },

    // eslint-disable-next-line no-unused-vars
    loadLogisticians(state) {},

    loadLogisticiansSuccess(state, action: PayloadAction<Array<UserBasicInfoModel>>) {
      state.logisticians = action.payload;
    },

    makeCommercialOffer(
      state,
      // eslint-disable-next-line no-unused-vars
      action: PayloadAction<{
        application: Omit<ApplicationModel, "type" | "creationDate" | "indicativeCreationDate" | "creator">;
        calculationIndex: number;
      }>,
    ) {
      state.loadingData = true;
    },

    makeCommercialOfferSuccess(
      state,
      action: PayloadAction<{
        application: ApplicationModel;
        calculationIndex: number;
      }>,
    ) {
      state.loadingData = false;
      state.application = action.payload.application;
    },
  },
});

export default salesApplicationSlice;
