import i18n from "../../../../../../i18n";

i18n.addResourceBundle("ru_RU", "translation", {
  login_form: {
    login_placeholder: "10-12 цифр",
    login_label: "ИНН",
    login_empty_error: "Введите ИНН",
    login_size_error: "ИНН должен быть 10-12 цифр",
    login_error_code_2: "Пользователь с указанным ИНН не найден",
    password_placeholder: "Пароль",
    password_label: "Пароль",
    password_empty_error: "Введите пароль",
    password_error_code_4: "Неверный пароль",
    is_new_user: "Новый пользователь?",
    register: "Зарегистрироваться",
    forgot_password: "Забыли пароль?",
    submit: "Войти",
  },
});
