import React, { useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";

import "./i18n";

import { AuthActions } from "../../../../auth/actions";

import { changeCurrentView } from "../../reducer";

import { makeSelectIsRegisterLoading } from "../../selectors";

import { H3 } from "../../../../components/Typegraphy/Heading";

import RegisterForm from "./components/RegisterForm";

import { RegisterInputModel } from "../../../../viewModels/Auth/RegisterInputModel";

function RegisterView() {
  const isRegisterLoading = useSelector(makeSelectIsRegisterLoading());
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleLogin = useCallback(
    (registerModel: RegisterInputModel) => {
      dispatch(AuthActions.register(registerModel));
    },
    [dispatch],
  );

  const handleOpenLoginView = useCallback(() => {
    dispatch(changeCurrentView("LOGIN_VIEW"));
  }, [dispatch]);

  return (
    <>
      <H3 margin="0 0 16px 0">{t("register_view.title")}</H3>
      <RegisterForm onRegister={handleLogin} loadingData={isRegisterLoading} onOpenLoginView={handleOpenLoginView} />
    </>
  );
}

export default React.memo(RegisterView);
