import React from "react";
import ReactDOM from "react-dom/client";

import dayjs from "dayjs";
import "dayjs/locale/ru";

import "react-datepicker/dist/react-datepicker.css";

import { ConfigProvider } from "antd";

import duration from "dayjs/plugin/duration";

import { createBrowserHistory } from "history";

import { Provider } from "react-redux";

// eslint-disable-next-line import/no-extraneous-dependencies
import "moment/locale/ru";
import locale from "antd/es/locale/ru_RU";

import CustomRouter from "./components/Router";

import App from "./containers/App";

import { store } from "./configureStore";

import "./i18n";

import "antd/dist/antd.min.css";

dayjs.locale("ru");
dayjs.extend(duration);

export const history = createBrowserHistory();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <Provider store={store}>
    <CustomRouter history={history}>
      <ConfigProvider locale={locale}>
        <App />
      </ConfigProvider>
    </CustomRouter>
  </Provider>,
);
