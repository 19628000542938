import React, { useCallback, useState } from "react";

import { Button } from "antd";
import { useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";

import "./i18n";

import { AuthActions } from "../../../../auth/actions";

import useTimer from "../../../../hooks/useTimer";

import { H3 } from "../../../../components/Typegraphy/Heading";
import { Text } from "../../../../components/Typegraphy/Text";

function SuccessRestorePasswordView() {
  const [version, setVersion] = useState(0);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { minutes, seconds, secondsLeft } = useTimer(60, version);

  const handleResendRestorePassword = useCallback(() => {
    dispatch(AuthActions.resendRestorePassword());
    setVersion((x) => x + 1);
  }, [dispatch]);

  return (
    <>
      <H3 margin="0 0 16px 0">{t("success_restore_password_view.title")}</H3>
      <Text margin={secondsLeft > 0 ? "0 0 20px 0" : "0 0 64px 0"}>{t("success_restore_password_view.text")}</Text>
      {secondsLeft > 0 && (
        <Text color="gray-600" margin="0 0 20px">
          <>
            {secondsLeft > 0 && t("success_restore_password_view.timer_text")} {minutes}:{seconds}
          </>
        </Text>
      )}
      <Button type="text" onClick={handleResendRestorePassword} disabled={secondsLeft > 0}>
        {t("success_restore_password_view.resend")}
      </Button>
    </>
  );
}

export default React.memo(SuccessRestorePasswordView);
