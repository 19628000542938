import React, { useEffect } from "react";

import { Form, Button, Row } from "antd";

import { useTranslation } from "react-i18next";
import "./i18n";

import { useSelector } from "react-redux";

import { makeSelectLoginFormErrors } from "../../../../selectors";

import Input from "../../../../../../components/MyInput";
import { Text } from "../../../../../../components/Typegraphy/Text";
import { PasswordWithFormLabel } from "../../../../../../components/Form/FormItemsWithLabel";

import { LoginFormProps, LoginFormInputModel } from "./types";

function LoginForm({ loadingData, onLogin, onOpenRestorePasswordView, onOpenRegisterView }: LoginFormProps) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const formErrors = useSelector(makeSelectLoginFormErrors());

  useEffect(() => {
    if (formErrors) {
      const errors = formErrors.map((x) => ({
        name: x.field,
        errors: [t(`login_form.${x.field}_error_code_${x.errorCode}`)],
      }));

      form.setFields(errors);
    }
  }, [formErrors, form, t]);

  const onFinish = ({ login, password }: LoginFormInputModel) => {
    onLogin({ login, password });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="login"
        rules={[
          () => ({
            validator(_, value: string) {
              if (value && value.length >= 10 && value.length <= 12) {
                return Promise.resolve();
              }
              if (!value) {
                return Promise.reject(t("login_form.login_empty_error"));
              }

              return Promise.reject(t("login_form.login_size_error"));
            },
          }),
        ]}
      >
        <Input placeholder={t("login_form.login_placeholder")!} label={t("login_form.login_label")!} mandatory type="only-numbers" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: t("login_form.password_empty_error")! }]}>
        <PasswordWithFormLabel placeholder={t("login_form.password_placeholder")!} label={t("login_form.password_label")!} mandatory />
      </Form.Item>
      <Form.Item>
        <Button type="text" onClick={onOpenRestorePasswordView}>
          {t("login_form.forgot_password")}
        </Button>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }} loading={loadingData}>
          {t("login_form.submit")}
        </Button>
      </Form.Item>

      <Row justify="center">
        <Text size={3} margin="0 4px 0">
          {t("login_form.is_new_user")}
        </Text>
        <Button onClick={onOpenRegisterView} type="text">
          {t("login_form.register")}
        </Button>
      </Row>
    </Form>
  );
}

export default React.memo(LoginForm);
